import React from "react";
import LayoutContainer from "./LayoutContainer";
import Survey from "./pages/Survey";
import "./i18n";

function App() {
	return (
		<div>
			<React.Suspense fallback={null}>
				<LayoutContainer>
					<Survey />
				</LayoutContainer>
			</React.Suspense>
		</div>
	);
}

export default App;
