import React from "react";

export const Brands = Object.freeze({
	STANLEY: "stanley_tools",
	BLACK_AND_DECKER: "blackanddecker",
	DEWALT: "dewalt",
	IRWIN: "irwin",
	CRAFTSMAN: "craftsman",
	BOSTITCH: "bostitch",
	PORTER_CABLE: "porter_cable",
	EXPERT: "expert",
	FACOM: "facom",
	LENOX: "lenox",
	MAC_TOOLS: "mac_tools",
	PROTO: "proto",
	SIDCHROME: "sidchrome",
	SERVICENET: "servicenet",
	USAG: "usag"
});

export const Languages = Object.freeze({
	ENGLISH: "en",
	SPANISH: "es",
	PORTUGUESE: "pt",
	BR_PORTUGUESE: "pt-br",
	TURKISH: "tr",
	KOREAN: "ko",
	JAPANESE: "ja",
	THAILAND: "th",
	IDN_BAHASA: "id",
	MLY_BAHASA: "ms",
	CHINESE: "zh",
	ARABIC: "ar",
	VIETNAMESE: "vi",
	DEUTSCH: "de",
	FRENCH: "fr",
	ITALIAN: "it",
	POLISH: "pl",
	DUTCH: "nl",
	DANISH: "da",
	NORWEGIAN: "no",
	SWEDISH: "sv",
	FINNISH: "fi",
	CZECH: "cs",
	SLOVAK: "sk",
	ROMANIAN:"ro",
	HUNGARIAN:"hu"
});

export const MAX_COMMENT_LENGTH = 300;

export const themes = {
	[Brands.STANLEY.toLowerCase()]: {
		backgroundColor: "#fbd504",
		buttonBackground: "#fbd504",
		logoPath: "",
		buttonTextColor: "#000",
	},
	[Brands.BLACK_AND_DECKER.toLowerCase()]: {
		backgroundColor: "#000",
		color: "#E25F26",
		// buttonBackground: "#E25F26",
		buttonBackground: "#000",
		// buttonTextColor: "#fff",
		buttonTextColor: "#E25F26",
		logoPath: "",
	},
	[Brands.DEWALT.toLowerCase()]: {
		backgroundColor: "#000000",
		color: "#FEBC18",
		buttonBackground: "#000000",
		buttonTextColor: "#FEBC18",
		logoPath: "",
	},
	[Brands.IRWIN.toLocaleLowerCase()]: {
		backgroundColor: "#000B6D",
		buttonBackground: "#000B6D",
		buttonTextColor: "#FFC528",
	},
	[Brands.CRAFTSMAN.toLocaleLowerCase()]: {
		backgroundColor: "#D6001C",
		buttonBackground: "#D6001C",
		buttonTextColor: "#FFFFFF",
	},
	[Brands.LENOX.toLocaleLowerCase()]: {
		backgroundColor: "#FFFFFF",
		buttonBackground: "#0039AA",
		buttonTextColor: "#FFFFFF",
	},
	[Brands.BOSTITCH.toLocaleLowerCase()]: {
		backgroundColor: "#000000",
		buttonBackground: "#000000",
		buttonTextColor: "#FFFFFF",
	},
	[Brands.PORTER_CABLE.toLocaleLowerCase()]: {
		backgroundColor: "#000000",
		buttonBackground: "#000000",
		buttonTextColor: "#FFFFFF",
	},
	[Brands.FACOM.toLowerCase()]: {
		backgroundColor: "#1B1B1F",
		buttonBackground: "#1B1B1F",
		buttonTextColor: "#FFFFFF",
	},
	[Brands.MAC_TOOLS.toLowerCase()]: {
		backgroundColor: "#E31837",
		buttonBackground: "#E31837",
		buttonTextColor: "#FFFFFF",
	},
	[Brands.SIDCHROME.toLowerCase()]: {
		backgroundColor: "#B01C2E",
		buttonBackground: "#B01C2E",
		buttonTextColor: "#FFFFFF",
	},
	[Brands.USAG.toLowerCase()]: {
		backgroundColor: "#FF0000",
		buttonBackground: "#FF0000",
		buttonTextColor: "#FFFFFF",
	},
	[Brands.EXPERT.toLowerCase()]: {
		backgroundColor: "#FFFFFF",
		buttonBackground: "#00448D",
		buttonTextColor: "#FFFFFF",
	},
	[Brands.SERVICENET.toLowerCase()]: {
		backgroundColor: "#7F7F7F",
		buttonBackground: "#7F7F7F",
		buttonTextColor: "#161616",
	}
};

export const BrandContext = React.createContext({
	name: Brands.STANLEY,
	prettyName: Brands.STANLEY.toUpperCase(),
	theme: themes.stanley,
	toggleTheme: () => {},
});

export const LanguageContext = React.createContext({
	language: Languages.ENGLISH,
	setLanguage: () => {},
});

export const namesBlacklist = [
	"apple",
	"user",
	"caller",
	"unknown"
]

export const isBlacklistedName = (name) => namesBlacklist.includes(name.toLowerCase());
