import React from "react";

import CsatSelector from "../components/CsatSelector";
import NpsSelector from "../components/NpsSelector";
import "./styles/Survey.css";
import { withTranslation } from "react-i18next";
import { BrandContext, isBlacklistedName, LanguageContext
 } from "../utils/Config";
import {
	QuestionTags,
	FIRST_QUESTION,
	SECOND_QUESTION,
	THIRD_QUESTION,
	FOURTH_QUESTION,
	FIFTH_QUESTION,
	EXTRA_EMAIL_TAG_QUESTION,
	EXTRA_PHONE_TAG_QUESTION
} from "../utils/Questions";

class Survey extends React.Component {
	hasComment() {
		const { emptyFields } = this.props.survey;
		return (
			emptyFields === undefined ||
			!emptyFields.includes(QuestionTags.SIXTH)
		);
	}

	renderEmptyFieldWarning(background, symbolColor, text) {
		return (
			<p className="Survey__empty-msg">
				<span
					className="Survey__empty-icon"
					style={{
						backgroundColor: background,
						color: symbolColor,
					}}
				>
					!
				</span>{" "}
				{text}
			</p>
		);
	}

	renderSurvey(brand, lang) {
		let { name, prettyName } = brand;
		let logoName = name;
		//logoName = logoName.
		const searchRegExp = / /g;
		const replaceWith = "_";
		logoName = logoName.replace(searchRegExp, replaceWith);
		logoName = logoName.toLowerCase();
		let brandLogo;
		try {
			brandLogo = require("../images/" + logoName + ".png");
		} catch (e) {
			brandLogo = require("../images/stanley_tools.png");
		}

		const {
			backgroundColor,
			buttonBackground,
			buttonTextColor,
		} = brand.theme;
		const { t, survey } = this.props;
		const { handleOptionChange, sendSurvey } = this.props;
		const customer = this.props.customerName;
		// let greeting =
		// 	customer !== undefined && !isBlacklistedName(customer)
		// 		? t("greeting.hello").replace("%NAME%", customer)
		// 		: t("greeting.hello").replace(" ", "").replace("%NAME%", "");
		let greeting = t("greeting.hello").replace(" ", "").replace("%NAME%", "");
		const { emptyFields } = survey;

		let noCommentStyle = { marginBottom: "85px" };
		if (!survey.isValidComment) {
			noCommentStyle = {
				...noCommentStyle,
				boxShadow: "0px 0px 6px rgb(129, 5, 5)",
			};
		}

		const tag = this.props.surveyTags ? this.props.surveyTags.split(" ") : [""];

		return (
			<div className="container bg-white">
				<div
					className="Survey__header"
					style={{ background: backgroundColor }}
				>
					<img className="my-auto align-self-center" src={brandLogo} alt="" />
				</div>
				<div className="Survey__greeting">
					<h5>
						<hr className="col-md-3 col-1" />
						{greeting}
						<hr className="col-md-3 col-1" />
					</h5>
					<p>
						{t("greeting.first-line")}
						<br />
						{t("greeting.second-line")}
					</p>
				</div>
				<div className="Survey__questions col-md-6 col-11">
					<CsatSelector
						optionNumber={'1.'}
						optionsAmount={5}
						question={t(FIRST_QUESTION)}
						tag={QuestionTags.FIRST}
						selectedOption={survey[QuestionTags.FIRST]}
						onChange={handleOptionChange}
						emptyFields={emptyFields}
						emptyMsgTextColor={buttonTextColor}
						emptyMsgBgColor={buttonBackground}
						lowestScale={t("csat.scale.lowest")}
						highestScale={t("csat.scale.highest")}
					/>

					{survey[QuestionTags.FIRST] == '5' && tag[0] == 'five9' && <CsatSelector
						optionNumber={''}
						optionsAmount={2}
						question={t(EXTRA_PHONE_TAG_QUESTION)}
						tag={QuestionTags.EXTRA}
						selectedOption={survey[QuestionTags.EXTRA]}
						onChange={handleOptionChange}
						emptyFields={emptyFields}
						emptyMsgTextColor={buttonTextColor}
						emptyMsgBgColor={buttonBackground}
						lowestScale={t("csat.scale.lowest")}
						highestScale={t("csat.scale.highest")}
					/>}

					{survey[QuestionTags.FIRST] == '5' && tag[0] != 'five9' && <div>
						<p className="Survey__question">{t(EXTRA_EMAIL_TAG_QUESTION)}</p>
						<textarea
							className="Survey__open-comment Survey__options form-control"
							onChange={this.props.handleModChange}
							value={survey[QuestionTags.EXTRA]}
							name=""
							id=""
							cols="30"
							rows="4"
							style={noCommentStyle}
						></textarea>
					</div>}

					{/* <CsatSelector
						optionNumber={'2.'}
						optionsAmount={5}
						question={t(SECOND_QUESTION)}
						tag={QuestionTags.SECOND}
						selectedOption={survey[QuestionTags.SECOND]}
						onChange={handleOptionChange}
						emptyFields={emptyFields}
						emptyMsgTextColor={buttonTextColor}
						emptyMsgBgColor={buttonBackground}
						lowestScale={t("csat.scale.lowest")}
						highestScale={t("csat.scale.highest")}
					/> */}

					{/* This question contains the %BRAND% var */}
					<CsatSelector
						optionNumber={'2.'}
						optionsAmount={5}
						question={t(THIRD_QUESTION).replace("%BRAND%", prettyName)}
						tag={QuestionTags.THIRD}
						selectedOption={survey[QuestionTags.THIRD]}
						onChange={handleOptionChange}
						emptyFields={emptyFields}
						emptyMsgTextColor={buttonTextColor}
						emptyMsgBgColor={buttonBackground}
						lowestScale={t("ces.scale.lowest")}
						highestScale={t("ces.scale.highest")}
					/>

					<CsatSelector
						optionNumber={'3.'}
						optionsAmount={2}
						question={t(FOURTH_QUESTION)}
						tag={QuestionTags.FOURTH}
						selectedOption={survey[QuestionTags.FOURTH]}
						onChange={handleOptionChange}
						emptyFields={emptyFields}
						emptyMsgTextColor={buttonTextColor}
						emptyMsgBgColor={buttonBackground}
					/>

					{/* This question contains the %BRAND% var */}
					<NpsSelector
						optionNumber={'4.'}
						question={t(FIFTH_QUESTION).replace("%BRAND%", prettyName)}
						selectedOption={survey[QuestionTags.FIFTH]}
						tag={QuestionTags.FIFTH}
						onChange={handleOptionChange}
						emptyFields={emptyFields}
						emptyMsgTextColor={buttonTextColor}
						emptyMsgBgColor={buttonBackground}
					/>

					{/* {!this.hasComment() &&
						this.renderEmptyFieldWarning(
							buttonBackground,
							buttonTextColor,
							t("pop-up.empty-fields.description")
						)} */}

					{!this.props.survey.isValidComment &&
						this.hasComment() &&
						this.renderEmptyFieldWarning(
							buttonBackground,
							buttonTextColor,
							t("invalid-comment")
						)}

					<p className="Survey__question">5. {t("open-comment")}</p>
					<textarea
						className="Survey__open-comment Survey__options form-control"
						onChange={this.props.handleCommentChange}
						value={survey[QuestionTags.SIXTH]}
						name=""
						id=""
						cols="30"
						rows="4"
						style={noCommentStyle}
					></textarea>
				</div>
				<button
					onClick={sendSurvey}
					style={{
						color: buttonTextColor,
						background: buttonBackground,
					}}
					disabled={survey.loading}
					className="col-md-3 col-7 Survey__send-button"
				>
					{survey.loading ? t("button.loading") : t("button.send")}
				</button>
			</div>
		);
	}

	render() {
		return (
			<React.Fragment>
				<LanguageContext.Consumer>
					{(langProvider) => {
						return (
							<BrandContext.Consumer>
								{(brand) => {
									return this.renderSurvey(
										brand,
										langProvider.language
									);
								}}
							</BrandContext.Consumer>
						);
					}}
				</LanguageContext.Consumer>
			</React.Fragment>
		);
	}
}

export default withTranslation()(Survey);
