import { Brands } from "./Config";
import { QuestionTags } from "../utils/Questions";

import { STANLEY_SERVICE, SURVEYS_SERVICE } from "../config/settings.json";

export async function getSurveyInfo(id) {
	let response = await fetch(`${SURVEYS_SERVICE}/${id}/get-by-id`, {
		method: "GET",
	});
	response = await response.json();
	return response;
}

export function surveyToJson(survey) {
	const csat = survey[QuestionTags.FIRST];
	const mod = csat === '5' ? survey[QuestionTags.EXTRA] : '';
	const time = survey[QuestionTags.SECOND];
	const ces = survey[QuestionTags.THIRD];
	const firstContact = survey[QuestionTags.FOURTH];
	const nps = survey[QuestionTags.FIFTH];
	const comment = survey[QuestionTags.SIXTH];
	const surveyJson = {
		csat,
		mod,
		time,
		ces,
		first_contact_resolution: firstContact,
		nps,
		open_comment: comment,
	};
	return surveyJson;
}

//const url = `${SURVEYS_SERVICE}/v1/custom-surveys/${surveyId}/send-answers`;

export async function sendSurvey(id, surveyJson) {
	surveyJson.csat = Number(surveyJson.csat);
	surveyJson.nps = Number(surveyJson.nps);
	surveyJson.ces = Number(surveyJson.ces);
	surveyJson.first_contact_resolution = Number(surveyJson.first_contact_resolution);
	surveyJson.time = Number(surveyJson.time);
	fetch(`${SURVEYS_SERVICE}/${id}/send-answers`, {
		headers: {
			Accept: "*/*",
			"Accept-Encoding": "gzip, deflate, br",
			"Content-Type": "application/json",
		},
		method: "PATCH",
		body: JSON.stringify(surveyJson),
	})
		.then((response) => {
			if (!response.ok) {
				console.log(`Error: Type ${response.type} Status ${response.status}`);
				console.log(response);
				return;
			}
			console.log(`Survey with ID ${response} sent`);
		})
		.catch((e) => {
			console.log("Error while sending the survey", e);
		});
}

export async function getTicketInfo(id) {
	let ticketJson;
	await fetch(`${STANLEY_SERVICE}/v2/tickets/${id}.json`, {
		method: "GET",
		headers: {
			Authorization: "Bearer c37245862ac78f98fd1b5ba50a6cd7d3c954aa81a10ebb35b8bc7f8a23c756c3",
			"Content-Type": "application/json",
			Accept: "application/json",
		},
	})
		.then((res) => {
			return res.json();
		})
		.then((res) => {
			ticketJson = res;
		})
		.catch((error) => console.error("Error: ", error));
	return ticketJson;
}

export async function getUserInfo(id) {
	let userJson;
	await fetch(`${STANLEY_SERVICE}/v2/users/${id}.json`, {
		method: "GET",
		headers: {
			Authorization: "Bearer c37245862ac78f98fd1b5ba50a6cd7d3c954aa81a10ebb35b8bc7f8a23c756c3",
			"Content-Type": "application/json",
			Accept: "application/json",
		},
	})
		.then((res) => res.json())
		.then((res) => (userJson = res))
		.catch((error) => console.error("Error: ", error));
	return userJson;
}

export async function getGroup(id) {
	let groupJson;
	await fetch(`${STANLEY_SERVICE}/v2/groups/${id}.json`, {
		method: "GET",
		headers: {
			Authorization: "Bearer c37245862ac78f98fd1b5ba50a6cd7d3c954aa81a10ebb35b8bc7f8a23c756c3",
			"Content-Type": "application/json",
			Accept: "application/json",
		},
	})
		.then((res) => {
			return res.json();
		})
		.then((res) => {
			groupJson = res;
		})
		.catch((error) => console.error("Error: ", error));
	return groupJson;
}

function getBigBrand(brandName) {
	brandName = brandName.toLowerCase();
	if (
		brandName.includes("black and decker") ||
		brandName.includes("bd") ||
		brandName.includes("black+decker") ||
		brandName.includes("black + decker")
	) {
		return Brands.BLACK_AND_DECKER;
	}
	if (brandName.includes("dewalt")) {
		return Brands.DEWALT;
	}
	if (brandName.includes("irwin")) {
		return Brands.IRWIN;
	}
	if (brandName.includes("craftsman")) {
		return Brands.CRAFTSMAN;
	}
	if (brandName.includes("bostitch")) {
		return Brands.BOSTITCH;
	}
	if (brandName.includes("lenox")) {
		return Brands.LENOX;
	}
	if (brandName.includes("porter cable")) {
		return Brands.PORTER_CABLE;
	}
	return Brands.STANLEY;
}

export async function getBrandName(id) {
	let brand;
	await fetch(`${STANLEY_SERVICE}/v2/brands/${id}.json`, {
		method: "GET",
		headers: {
			Authorization: "Bearer c37245862ac78f98fd1b5ba50a6cd7d3c954aa81a10ebb35b8bc7f8a23c756c3",
			"Content-Type": "application/json",
			Accept: "application/json",
		},
	})
		.then((res) => {
			return res.json();
		})
		.then((res) => {
			brand = res.brand.name;
		})
		.catch((error) => console.error("Error: ", error));
	return getBigBrand(brand);
}
