export const NOT_FOUND = undefined;

export class Country {
	/**
	 * 
	 * @param {String} name Name of the country Ex: Colombia
	 * @param {Object} websitesObject Object with all the websites of each brand (one per brand) Ex: {blackanddecker: https://co.blackanddecker.global, dewalt: https://co.dewalt.global/}
	 */
	constructor(name, websitesObject) {
		this.name = name;
		this.websites = websitesObject;
		this.aliases = [];
	}

	hasAliases() {
		return this.aliases.length !== 0;
	}

	/**
	 *
	 * Search the website for specified brand
	 * @param {String} brand
	 */
	getBrandWebsite(brand) {
		if (this.isValidBrand(brand)) {
			brand = brand.toLowerCase();
			brand = brand.replace(/ /g, "");
			return this.websites[brand];
		}
	}

	/**
	 *
	 * @param {String} groupName Name of the group you want to verify (EX: Smart Order Brazil)
	 */
	isOwnerGroup(groupName) {
		if (this.isValidGroupName(groupName)) {
			groupName = groupName.toLowerCase();
			return (
				this.checkByName(groupName) || this.checkByAliases(groupName)
			);
		}		
		return false;
	}

	checkByName(groupName) {
		const nameLower = this.name.toLowerCase();
		return groupName.includes(nameLower);
	}

	checkByAliases(groupName) {
		if (this.hasAliases()) {
			for (const alias of this.aliases) {
				if (groupName.toLowerCase().includes(alias.toLowerCase())) {
					return true;
				}
			}
		}
		return false;
	}
	
	isValidGroupName(groupName) {
		return groupName !== null && groupName !== undefined;
	}

	isValidBrand(brandName) {
		return brandName !== null && brandName !== undefined;
	}

	/**
	 *
	 * @param {String} name Country's name EX: Colombia
	 * @param {Array<String>} aliases Array with other possible names which will be used to check if
	 *                         a group is from this country
	 * @param {String} dewaltWeb DeWalt web site URL
	 * @param {String} stanleyWeb Stanley web site URL
	 * @param {String} blackAndDeckerWeb Black and Decker web site URL
	 * @param {String} irwinWeb Irwin web site URL
	 * @param {String} craftsmanWeb Craftsman web site URL
	 */
	static createWithAliases(
		name,
		aliases,
		dewaltWeb,
		stanleyWeb,
		blackAndDeckerWeb,
		irwinWeb,
		craftsmanWeb
	) {
		let country = new Country(
			name,
			dewaltWeb,
			stanleyWeb,
			blackAndDeckerWeb,
			irwinWeb,
			craftsmanWeb
		);
		country.aliases = aliases;
		return country;
	}
}

/**
 * Use region when there are different countries using
 * the same website.
 */
export class Region extends Country {
	/**
	 *
	 * @param {String} name Region's name EX: Middle East
	 * @param {Array} countries Array with the name of each country inside this region
	 * @param {String} dewaltWeb DeWalt web site URL
	 * @param {String} stanleyWeb Stanley web site URL
	 * @param {String} blackAndDeckerWeb Black and Decker web site URL
	 * @param {String} irwinWeb Irwin web site URL
	 * @param {String} craftsmanWeb Craftsman web site URL
	 */
	constructor(
		name,
		countries,
		dewaltWeb,
		stanleyWeb,
		blackAndDeckerWeb,
		irwinWeb,
		craftsmanWeb
	) {
		super(name, dewaltWeb, stanleyWeb, blackAndDeckerWeb, irwinWeb, craftsmanWeb);
		this.countries = countries;
	}

	isOwnerGroup(groupName) {
		if (this.isValidGroupName(groupName)) {
			groupName = groupName.toLowerCase();
			if (this.countries !== null && this.countries !== undefined) {
				for (let country of this.countries) {
					country = country.toLowerCase();
					if (groupName.includes(country)) {
						return true;
					}
				}
			}
		}
		return false;
	}
}
