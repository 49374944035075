import React from "react";
import swal from "sweetalert";
import Survey from "./Survey";
import { withTranslation } from "react-i18next";
import { Languages, MAX_COMMENT_LENGTH } from "../utils/Config";
import { QuestionTags } from "../utils/Questions";
import { redirectToBrandWebsiteByGroup } from "../utils/country/CountryManager";
import { surveyToJson, sendSurvey } from "../utils/ApiManager";
import { isRequiredField } from "../utils/Questions";

class SurveyContainer extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			lang: Languages.ENGLISH,
			[QuestionTags.FIRST]: "",
			[QuestionTags.EXTRA]: "",
			[QuestionTags.SECOND]: "",
			[QuestionTags.THIRD]: "",
			[QuestionTags.FOURTH]: "",
			[QuestionTags.FIFTH]: "",
			[QuestionTags.SIXTH]: "",
			isValidComment: true,
			loading: false,
			recentlyAnswered: false,
		};
		this.handleOptionChange = this.handleOptionChange.bind(this);
		this.handleModChange = this.handleModChange.bind(this);
		this.handleCommentChange = this.handleCommentChange.bind(this); 
		this.sendSurvey = this.sendSurvey.bind(this);
	}

	handleOptionChange(changeEvent) {
		const selection = changeEvent.target;
		const tag = selection.id.split("-")[0];
		this.setState({
			[tag]: selection.getAttribute("data-option"),
		});
	}

	handleModChange(changeEvent) {
		let { value } = changeEvent.target;
		this.setState({
			...this.state,
			[QuestionTags.EXTRA]: value,
			isValidComment: value.length <= MAX_COMMENT_LENGTH,
		});
	}

	handleCommentChange(changeEvent) {
		let { value } = changeEvent.target;
		this.setState({
			...this.state,
			[QuestionTags.SIXTH]: value,
			isValidComment: value.length <= MAX_COMMENT_LENGTH,
		});
	}

	onSuccess = (t) => {
		this.setState({
			...this.state,
			loading: false,
			recentlyAnswered: true,
		});
		swal({
			title: t("pop-up.success.title"),
			text: t("pop-up.success.description"),
			icon: "success",
			button: t("pop-up.button-text"),
		}).then((value) => this.redirectToBrandPage());
	};

	onFailure = (t, error) => {
		console.log("Error: " + error);
		setTimeout(this.setState({ ...this.state, loading: false, error: error }), 1000);
		swal({
			title: t("pop-up.error.title"),
			text: `${t("pop-up.error.description").replace("%ERROR%", error.message)}`,
			icon: "error",
			button: t("pop-up.button-text"),
		});
	};

	areEmptyFields() {
		const emptyFields = [];
		for (const tag of Object.values(QuestionTags)) {
			if (!this.state[tag] && isRequiredField(tag)) {
				emptyFields.push(tag);
			}
		}
		this.setState({
			...this.state,
			emptyFields: emptyFields,
		});
		return emptyFields.length !== 0;
	}

	isBadRating() {
		const csat = this.state[QuestionTags.FIRST];
		// const mod = this.state[QuestionTags.EXTRA];
		const time = this.state[QuestionTags.SECOND];
		const ces = this.state[QuestionTags.THIRD];
		const firstContact = this.state[QuestionTags.FOURTH];
		const nps = this.state[QuestionTags.FIFTH];
		if (csat <= 2 || time <= 2 || ces <= 2 || firstContact === 0 || nps <= 6) {
			return true;
		}
		return false;
	}

	async redirectToBrandPage() {
		if (this.props.brand) {
			const brand = this.props.brand.replace('®', '').toLowerCase().replace(/ /g, '_').replace('+', 'and');
			redirectToBrandWebsiteByGroup(this.props.groupName,brand);
		}
	}

	async sendSurvey(e, t) {
		e.preventDefault();
		if (!this.props.isValidUrl) {
			swal({
				title: t("pop-up.invalid-link.title"),
				text: t("pop-up.invalid-link.description"),
				icon: "error",
				button: t("pop-up.button-text"),
			});
			return;
		}		
		if (this.props.surveyHasAnswer || this.state.recentlyAnswered) {
			swal({
				title: t("pop-up.survey-has-answer.title"),
				text: t("pop-up.survey-has-answer.description"),
				icon: "error",
				button: t("pop-up.button-text"),
			});
			return;
		}				
		if (this.areEmptyFields()) {
			swal({
				title: t("pop-up.empty-fields.title"),
				text: t("pop-up.empty-fields.description"),
				icon: "error",
				button: t("pop-up.button-text"),
			});
			return;
		}
		if (!this.state.isValidComment) {
			swal({
				title: t("pop-up.error.title"),
				text: t("invalid-comment"),
				icon: "error",
				button: t("pop-up.button-text"),
			});
			return;
		}
		this.setState({ ...this.state, loading: true });
		const surveyJson = surveyToJson(this.state);
		await sendSurvey(this.props.surveyId, surveyJson)
		.then(() => {
			this.onSuccess(t)
		})
		.catch((e) => {
			console.error(e);
			this.setState({ ...this.state, loading: false });
			this.onFailure(t, e);
		})
	}
	
	render() {
		const { t } = this.props;
		return (
			<Survey
				customerName={this.props.customerName}
				surveyTags={this.props.surveyTags}
				handleOptionChange={this.handleOptionChange}
				handleModChange={this.handleModChange}
				handleCommentChange={this.handleCommentChange}
				sendSurvey={(e) => this.sendSurvey(e, t)}
				survey={this.state}
			/>
		);
	}
}
export default withTranslation()(SurveyContainer);
