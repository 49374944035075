import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
// import App from "./App.test";
import "../node_modules/bootstrap/dist/css/bootstrap.css";
import "../node_modules/font-awesome/css/font-awesome.min.css";
import "../node_modules/react-loader-spinner/dist/loader/css/react-spinner-loader.css";

ReactDOM.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
	document.getElementById("root")
);
