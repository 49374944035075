import React from "react";
import "./styles/CsatSelector.css";
import "../pages/styles/Survey.css";
import { withTranslation } from "react-i18next";

/**
 * Component with multiple options for CSAT questions
 *
 * @component
 *
 * Props:
 * @param {int} optionNumer Number used to enumerate the options
 * @param {string} tag Value used to recognize this selector and options from others
 * @param {string} label Label to show the full question in the form
 * @param {string} selectedOption Current selected option to render whith checked prop as true
 * @param {function} onChange Function executed when a different option is selected
 *
 */
class CsatSelector extends React.Component {
	constructor(props) {
		super(props);
		this.onClickOption = this.onClickOption.bind(this);
		this.mouseOverStar = this.mouseOverStar.bind(this);
		this.mouseLeaveStar = this.mouseLeaveStar.bind(this);
	}

	/**
	 * Used to reset the color/background of a specific option
	 *
	 * @param {htmlElement} option The html option. Stars are spans.
	 */
	resetOption(option) {
		const isStar = option.nodeName !== "BUTTON";
		if (isStar) {
			option.style.color = "#aaa";
		} else {
			option.style.backgroundColor = "#fff";
		}
	}

	/**
	 * Function used to reset the color/background of options in a specific container.
	 *
	 * @param {htmlElement} container Parent element of a group of options.
	 */
	resetContainerOptions(container) {
		const selectedOption = this.props.selectedOption;
		for (const option of container.children) {
			const optionNumber = option.getAttribute("data-option");
			// Reset color if the star value is bigger than selected one
			// Otherwise the stars stay filled
			// So if user voted 4 the stars number 1,2,3 and 4 stay filled and 5 has been restarted
			// The description below only works if the option clicked is a star
			if (selectedOption < optionNumber || option.nodeName === "BUTTON") {
				this.resetOption(option);
			}
		}
	}

	/**
	 * Fill a binary option (represented with a button)
	 *
	 * @param {htmlButton} button Option that is going to be filled
	 * @param {int} btnNumber Number stored in data-option of the button.
	 */
	fillSelectedButton(button, btnNumber) {
		let color = "#2ca5005d";
		switch (btnNumber) {
			case "0":
				color = "#c700005d";
				break;
			case "1":
				color = "#2ca5005d";
				break;
			default:
				color = "#2ca5005d";
				break;
		}
		button.style.backgroundColor = color;
	}

	/**
	 *
	 * @param {htmlSpan} star Option is going to be color changed
	 * @param {int} starNumber Number stored in data-option of the span.
	 */
	fillStar(star, starNumber) {
		let color = "#2BA500";
		switch (starNumber) {
			case "1":
				color = "#CC1818";
				break;
			case "2":
				color = "#F3565B";
				break;
			case "3":
				color = "#F0C700";
				break;
			case "4":
				color = "#33B903";
				break;
			case "5":
				color = "#2BA500";
				break;
			default:
				color = "#2BA500";
				break;
		}
		star.style.color = color;
	}

	/**
	 * Fills the stars from 1 to <starNumber> to generate the animation
	 *
	 * @param {htmlSpan} star Option with which user interact
	 * @param {*} starNumber Number stored in data-option of the span.
	 */
	fillStarRange(star, starNumber) {
		const container = star.parentNode;
		for (const otherStar of container.children) {
			// Check if this star is before the current selection
			// if yes it fills that star with its respective color
			const otherStarNumber = otherStar.getAttribute("data-option");
			if (otherStarNumber <= starNumber) {
				this.fillStar(otherStar, otherStarNumber);
			}
		}
	}

	/**
	 * Fills the option selected by user. It is used in both cases:
	 * Star clicked and button clicked
	 *
	 * @param {htmlElement} option Option clicked by the user
	 */
	selectOption(option) {
		// Buttons are used for the binary selection
		// Spans are used for stars so if it's not a button it's not a star
		const isStar = option.nodeName !== "BUTTON";
		const optNumber = option.getAttribute("data-option");
		if (isStar) {
			this.fillStarRange(option, optNumber);
		} else {
			this.fillSelectedButton(option, optNumber);
		}
	}

	/**
	 * Function called when any option is clicked (buttons and stars)
	 * And executes the onChange prop
	 *
	 * @param {event} e
	 */
	onClickOption(e) {
		this.props.onChange(e);
		const container = e.target.parentNode;
		this.resetContainerOptions(container);
		this.selectOption(e.target);
	}

	checkIfEmpty() {
		return (
			this.props.emptyFields &&
			this.props.emptyFields.includes(this.props.tag)
		);
	}

	/**
	 * Function used to listen the mouse over event and execute the
	 * fill animation
	 *
	 * @param {event} e
	 */
	mouseOverStar(e) {
		const star = e.target;
		const starNumber = star.getAttribute("data-option");
		this.fillStarRange(star, starNumber);
	}

	/**
	 * Function used to listen the mouse leave event and removes
	 * the color of not selected options
	 *
	 * @param {event} e
	 */
	mouseLeaveStar(e) {
		const star = e.target;
		this.resetContainerOptions(star.parentNode);
	}

	renderBinarySelector() {
		return (
			<React.Fragment>
				<div
					className={`Survey__options`}
					style={{ marginBottom: "85px" }}
				>
					<button
						id={`${this.props.tag}-0`}
						onClick={this.onClickOption}
						data-option={0}
						className="CsatSelector__option-button CsatSelector__x-button col-md-4 col-5"
					></button>
					<button
						id={`${this.props.tag}-1`}
						onClick={this.onClickOption}
						data-option={1}
						className="CsatSelector__option-button CsatSelector__check-button col-md-4 col-5"
					></button>
				</div>
			</React.Fragment>
		);
	}

	renderMultipleSelector(t) {
		const stars = [];
		for (let i = this.props.optionsAmount; i > 0; i--) {
			stars.push(
				// The id notation allows to identify the tag of the question
				// and its value (i) by using the split method on the id or the key
				<span
					id={`${this.props.tag}-${i}`}
					key={`${this.props.tag}-${i}`}
					onClick={this.onClickOption}
					onMouseOver={this.mouseOverStar}
					onMouseLeave={this.mouseLeaveStar}
					data-option={i}
					className="fa fa-star"
				></span>
			);
		}
		const lowestIndicator = this.props.lowestScale;
		const highestIndicator = this.props.highestScale;

		return (
			<React.Fragment>
				<div
					id="select-1"
					className={`Survey__options CsatSelector__stars`}
				>
					{stars}
				</div>
				<p className="Survey__indicators" style={{ textAlign: "left" }}>
					{lowestIndicator}
				</p>
				<p
					className="Survey__indicators"
					style={{ textAlign: "right" }}
				>
					{highestIndicator}
				</p>
			</React.Fragment>
		);
	}

	render() {
		const { t } = this.props;
		return (
			<React.Fragment>
				{this.checkIfEmpty() && (
					<p className="Survey__empty-msg">
						<span
							className="Survey__empty-icon"
							style={{
								backgroundColor: this.props.emptyMsgBgColor,
								color: this.props.emptyMsgTextColor,
							}}
						>
							!
						</span>{" "}
						{t("empty-field")}
					</p>
				)}
				<p className="Survey__question">{`${this.props.optionNumber} ${this.props.question}`}</p>
				{(this.props.optionsAmount === 2 &&
					this.renderBinarySelector()) ||
					this.renderMultipleSelector(t)}
			</React.Fragment>
		);
	}
}

export default withTranslation()(CsatSelector);
