export const QuestionTags = Object.freeze({
	FIRST: "csat1",
	SECOND: "csat2",
	THIRD: "csat3",
	FOURTH: "csat4",
	FIFTH: "nps",
	SIXTH: "comment",
	EXTRA: "mod1"
});

const CSAT_NODE = "csat.questions";
const MOD_NODE = "mod.questions";

export const FIRST_QUESTION = `${CSAT_NODE}.first`;
export const SECOND_QUESTION = `${CSAT_NODE}.second`;
export const THIRD_QUESTION = `${CSAT_NODE}.third`;
export const FOURTH_QUESTION = `${CSAT_NODE}.fourth`;

export const FIFTH_QUESTION = "nps.questions.first";
export const SIEXTH_QUESTION = "open-question";

export const EXTRA_EMAIL_TAG_QUESTION = `${MOD_NODE}.email-chat`;
export const EXTRA_PHONE_TAG_QUESTION = `${MOD_NODE}.phone`;

const CSAT_QUALI_NODE = "csat.qualifications";
export const CsatOptions = Object.freeze({
	LOWER: `${CSAT_QUALI_NODE}.lower`,
	LOW: `${CSAT_QUALI_NODE}.low`,
	MID: `${CSAT_QUALI_NODE}.mid`,
	HIGH: `${CSAT_QUALI_NODE}.high`,
	HIGHEST: `${CSAT_QUALI_NODE}.highest`,
});

const fieldsNotRequired = [QuestionTags.SIXTH, QuestionTags.EXTRA, QuestionTags.SECOND];


export function isRequiredField(questionTag) {
	return !fieldsNotRequired.includes(questionTag);
}
