import { CountryEnum } from "./Countries";
import { Brands } from "../Config";

function getDefaultPage(brand) {
	switch (brand) {
		case Brands.DEWALT:
			return "https://www.dewalt.com/";
		case Brands.BOSTITCH:
			return "https://www.bostitch.com/";
		case Brands.LENOX:
			return "https://www.lenoxtools.com/";
		case Brands.PORTER_CABLE:
			return "https://www.portercable.com/"; 
		case Brands.BLACK_AND_DECKER:
			return "https://www.blackanddecker.com/";
		case Brands.IRWIN:
			return "https://www.irwin.com/";
		case Brands.CRAFTSMAN:
			return 'https://cloud.emails.craftsman.com/craftsman_customer_service_sign_up';	
		case Brands.SERVICENET:
			return 'https://www.toolservicenet.com/';				
		default:
			return "https://www.stanleytools.com/";
	}
}

export function redirectToDefaultWebsite(brand) {
	const websiteUrl = getDefaultPage(brand);
	if (websiteUrl !== null) window.location.replace(websiteUrl);
}

/**
 *
 * @param {Country} country
 * @param {String} brand
 */
function redirectToBrandWebsiteByCountry(country, brand) {
	let websiteUrl = country.getBrandWebsite(brand);
	if (websiteUrl === undefined) {
		websiteUrl = getDefaultPage(brand);
	}
	window.location.replace(websiteUrl);
}

/**
 *
 * @param {String} groupName Group to which you want to find the country
 */
export function redirectToBrandWebsiteByGroup(groupName, brand) {
	for (const country of Object.values(CountryEnum)) {
		if (country.isOwnerGroup(groupName)) {
			redirectToBrandWebsiteByCountry(country, brand);
			return;
		}
	}
	redirectToDefaultWebsite(brand);
}
