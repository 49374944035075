import React from "react";
import "./styles/NpsSelector.css";
import { withTranslation } from "react-i18next";

const NpsOption = Object.freeze({
	LOW: "low",
	MID: "mid",
	HIGH: "high",
});

class NpsSelector extends React.Component {
	constructor(props) {
		super(props);
		this.onClickOption = this.onClickOption.bind(this);
	}

	/**
	 *
	 * @param {int} calif Equivalent calification of the option
	 * @param {string} califClass Class to add style to the option
	 */
	renderOption(calif, califClass) {
		return (
			<div
				id={`${this.props.tag}-${calif}`}
				key={calif}
				data-option={calif}
				onClick={this.onClickOption}
				className={`NpsSelector__option ${califClass}`}
			>
				{calif}
			</div>
		);
	}

	/**
	 *
	 * @param {NpsOption} type
	 * @param {*} minCalif
	 * @param {*} maxCalif
	 */
	renderNpsOptions(type, minCalif, maxCalif) {
		const options = [];
		for (let calif = minCalif; calif <= maxCalif; calif++) {
			let califClass = "";
			switch (type) {
				case NpsOption.LOW:
					califClass = "NpsSelector__low-calif";
					break;
				case NpsOption.MID:
					califClass = "NpsSelector__mid-calif";
					break;
				case NpsOption.HIGH:
					califClass = "NpsSelector__high-calif";
					break;
				default:
					califClass = "NpsSelector__high-calif";
					break;
			}
			options.push(this.renderOption(calif, califClass));
		}
		return options;
	}

	resetContainerOptions(container) {
		for (const option of container.children) {
			option.style.border = null;
		}
	}

	selectOption(option) {
		option.style.border = "4px solid gray";
	}

	onClickOption(e) {
		this.props.onChange(e);
		const container = e.target.parentNode;
		this.resetContainerOptions(container);
		this.selectOption(e.target);
	}

	checkIfEmpty() {
		return (
			this.props.emptyFields &&
			this.props.emptyFields.includes(this.props.tag)
		);
	}

	render() {
		const { t } = this.props;
		const lowCalifOptions = this.renderNpsOptions(NpsOption.LOW, 0, 6);
		const midCalifOptions = this.renderNpsOptions(NpsOption.MID, 7, 8);
		const highCalifOptions = this.renderNpsOptions(NpsOption.HIGH, 9, 10);
		return (
			<React.Fragment>
				{this.checkIfEmpty() && (
					<p className="Survey__empty-msg">
						<span
							className="Survey__empty-icon"
							style={{
								backgroundColor: this.props.emptyMsgBgColor,
								color: this.props.emptyMsgTextColor,
							}}
						>
							!
						</span>{" "}
						{t("empty-field")}
					</p>
				)}
				<p className="Survey__question">
					{`${this.props.optionNumber} ${this.props.question}`}
				</p>

				<div className="NpsSelector">
					<div className="NpsSelector__options corrector">
						{lowCalifOptions}
						{midCalifOptions}
						{highCalifOptions}
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default withTranslation()(NpsSelector);
