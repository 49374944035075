import React from "react";
import SurveyContainer from "./pages/SurveyContainer";
import { LanguageContext, BrandContext } from "./utils/Config";

class Layout extends React.Component {
	render() {
		const { customerName, langContext, brandContext, surveyId, brand, surveyTags, groupName } = this.props.context;
		return (
			<React.Fragment>
				<BrandContext.Provider value={brandContext}>
					<LanguageContext.Provider value={langContext}>
						{/* <Navbar /> */}

						<SurveyContainer
							customerName={customerName}
							surveyId={surveyId}
							groupName={groupName}
							brand={brand}
							surveyTags={surveyTags}
							isValidUrl={this.props.isValidUrl}
							surveyHasAnswer={this.props.surveyHasAnswer}
						/>
						{/* {this.props.children} */}
					</LanguageContext.Provider>
				</BrandContext.Provider>
			</React.Fragment>
		);
	}
}

export default Layout;
