import { Country, Region, NOT_FOUND } from "./Country";
import { Brands } from "../Config";

export const CountryEnum = Object.freeze({
	CCA: new Region(
		"CCA",
		[
			"Dominican Republic",
			"Trinidad and Tobago",
			"Guyana",
			"Jamaica",
			"Suriname",
			"Haití",
			"Curaçao",
			"The Bahamas",
			"Barbados",
			"Aruba",
			"Bonaire",
			"Puerto Rico",
		],
		{
			[Brands.DEWALT]: "https://cca.dewalt.global/es/",
			[Brands.STANLEY]: "https://cca.stanleytools.global/es/",
			[Brands.BLACK_AND_DECKER]: "https://cca.blackanddecker.global/es/",
			[Brands.IRWIN]: "https://hola.irwin-la.com/centroamerica/"
		}
	),

	MIDDLE_EAST: new Region(
		"Middle East",
		[
			"Bahrain",
			"Egypt",
			"Kuwait",
			"Oman",
			"Qatar",
			"Saudi Arabia",
			"UAE",
			"META",
			"Costa Rica",
			"El Salvador",
			"Guatemala",
			"Honduras",
			"Nicaragua",
			"Panama",
		],
		{
			[Brands.DEWALT]: "https://me.dewalt.global/",
			[Brands.STANLEY]: "https://me.stanleytools.global/",
			[Brands.BLACK_AND_DECKER]: "https://me.blackanddecker.global/",
		}
	),

	// Because there is a group called
	// "Reclame Aqui Brasil" it is necessary to
	// include both cases (Brazil and Brasil)
	BRAZIL: Country.createWithAliases(
		"Brazil",
		["Brasil"],
		{
			[Brands.DEWALT]: "https://br.dewalt.global/",
			[Brands.STANLEY]: "https://br.stanleytools.global/pt-br/",
			[Brands.BLACK_AND_DECKER]: "https://br.blackanddecker.global/pt-br/",
			[Brands.IRWIN]: "https://novidades.irwin.com.br/"
		}
	),
	ARGENTINA: new Country(
		"Argentina",
		{
			[Brands.DEWALT]: "https://ar.dewalt.global/index.php/servicio-y-ayuda/contact-us",
			[Brands.STANLEY]: "https://ar.stanleytools.global/es/",
			[Brands.BLACK_AND_DECKER]: "https://ar.blackanddecker.global/es/",
			[Brands.IRWIN]: "http://hola.irwin.com.ar/"}
	),
	ASIA: new Region("Asia"),
	CAMBODIA: new Country(
		"Cambodia",
		{
			[Brands.DEWALT]: "https://kh.dewalt.global",
			[Brands.STANLEY]: "https://kh.stanleytools.global",
			[Brands.BLACK_AND_DECKER]: "https://kh.blackanddecker.global",
		}
	),
	CHILE: new Country(
		"Chile",
		{
			[Brands.DEWALT]: "https://cl.dewalt.global/",
			[Brands.STANLEY]: "https://cl.stanleytools.global/es/contact-us",
			[Brands.BLACK_AND_DECKER]:"https://cl.blackanddecker.global/es/contacto",
			[Brands.IRWIN]: "https://hola.irwin.cl/contacto/"
		}
	),
	COLOMBIA: new Country(
		"Colombia",
		{
			[Brands.DEWALT]: "https://co.dewalt.global/",
			[Brands.STANLEY]: "https://co.stanleytools.global/es/",
			[Brands.BLACK_AND_DECKER]: "https://co.blackanddecker.global/",
			[Brands.IRWIN]: "https://hola.irwin.com.co/"
		}
	),
	CURACAO: new Country("Curaçao"),
	ECUADOR: new Country(
		"Ecuador",
		{
			[Brands.DEWALT]: "https://ec.dewalt.global",
			[Brands.STANLEY]: "https://ec.stanleytools.global",
			[Brands.BLACK_AND_DECKER]: "https://ec.blackanddecker.global",
		}
	),
	
	INDIA: new Country(
		"India",
		{
			[Brands.DEWALT]: "https://in.dewalt.global/service-and-support/contact-us",
			[Brands.STANLEY]: "https://in.stanleytools.global/contact-us",
			[Brands.BLACK_AND_DECKER]: "https://in.blackanddecker.global/en/contact-us",
		}
	),
	INDONESIA: new Country(
		"Indonesia",
		{
			[Brands.DEWALT]: "https://id.dewalt.global/",
			[Brands.STANLEY]: "https://id.stanleytools.global/",
			[Brands.BLACK_AND_DECKER]: "https://id.blackanddecker.global/en/",
		}
	),
	SOUTH_AFRICA: new Country(
		"South Africa",
		{
			[Brands.DEWALT]: "https://za.dewalt.global",
			[Brands.STANLEY]: "https://za.stanleytools.global",
			[Brands.BLACK_AND_DECKER]: "https://za.blackanddecker.global",
		}
	),
	JAPAN: new Country(
		"Japan",
		{
			[Brands.DEWALT]: "https://jp.dewalt.global/",
			[Brands.STANLEY]: "https://jp.stanleytools.global/",
			[Brands.BLACK_AND_DECKER]: "https://jp.blackanddecker.global/ja/",
		}
	),
	LAOS: new Country(
		"Laos",
		{
			[Brands.DEWALT]: "https://la.dewalt.global",
			[Brands.STANLEY]: "https://la.stanleytools.global",
			[Brands.BLACK_AND_DECKER]: "https://la.blackanddecker.global",
		}
	),
	MALAYSIA: new Country(
		"Malaysia",
		{
			[Brands.DEWALT]: "https://my.dewalt.global/contact-us",
			[Brands.STANLEY]: "https://my.stanleytools.global/contact-us",
			[Brands.BLACK_AND_DECKER]: "https://my.blackanddecker.global/en/contact-us",
		}
	),
	MEXICO: new Country(
		"Mexico",
		{
			[Brands.DEWALT]: "https://mx.dewalt.global/",
			[Brands.STANLEY]: "https://mx.stanleytools.global/",
			[Brands.BLACK_AND_DECKER]: "https://mx.blackanddecker.global/",
			[Brands.IRWIN]: "https://hola.irwin.com.mx/"
		}
	),
	PARAGUAY: new Country(
		"Paraguay",
		{
			[Brands.DEWALT]: "https://py.dewalt.global",
			[Brands.STANLEY]: "https://py.stanleytools.global",
			[Brands.BLACK_AND_DECKER]: "https://py.blackanddecker.global",
		}
	),
	PERU: new Country(
		"Peru",
		{
			[Brands.DEWALT]: "https://pe.dewalt.global/",
			[Brands.STANLEY]: "https://pe.stanleytools.global/es/",
			[Brands.BLACK_AND_DECKER]: "https://pe.blackanddecker.global/es/",
			[Brands.IRWIN]: "https://hola.irwin.com.pe/"
		}
	),
	PHILIPPINES: new Country(
		"Philipines",
		{
			[Brands.DEWALT]: "https://ph.dewalt.global/",
			[Brands.STANLEY]:"https://ph.stanleytools.global/",
			[Brands.BLACK_AND_DECKER]: "https://ph.blackanddecker.global/en/",
		}
	),
	SINGAPORE: new Country(
		"Singapore",
		{
			[Brands.DEWALT]: "https://sg.dewalt.global/",
			[Brands.STANLEY]: "https://sg.stanleytools.global/contact-us",
			[Brands.BLACK_AND_DECKER]: "https://sg.blackanddecker.global/en/contact-us",
		}
	),
	SOUTH_KOREA: Country.createWithAliases(
		"South Korea",
		["Smart Order Korea", "South Korea"],
		{
			[Brands.DEWALT]: "https://kr.dewalt.global/",
			[Brands.STANLEY]: "https://kr.stanleytools.global/ko-KR",
			[Brands.BLACK_AND_DECKER]: "https://kr.blackanddecker.global/ko-KR/",
		}
	),
	STANLEY_EARTH: new Country("Stanley Earth", {[Brands.STANLEY]: "https://www.stanleyearth.com/" }),
	LAG: new Country("Support LAG"),
	SURINAME: new Country("Suriname"),
	TAIWAN: new Country(
		"Taiwan",
		{
			[Brands.DEWALT]: "https://tw.dewalt.global",
			[Brands.STANLEY]: "https://tw.stanleytools.global",
			[Brands.BLACK_AND_DECKER]: "https://tw.blackanddecker.global",
		}
	),
	THAILAND: new Country(
		"Thailand",
		{
			[Brands.DEWALT]: "https://th.dewalt.global/th/",
			[Brands.STANLEY]: "https://th.stanleytools.global/",
			[Brands.BLACK_AND_DECKER]: "https://th.blackanddecker.global/th-TH/",
		}
	),
	TURKEY: new Country(
		"Turkey",
		{
			[Brands.DEWALT]: "https://tr.dewalt.global/",
			[Brands.STANLEY]: "https://tr.stanleytools.global/",
			[Brands.BLACK_AND_DECKER]: "https://tr.blackanddecker.global/",
		}
	),
	URUGUAY: new Country(
		"Uruguay",
		{
			[Brands.DEWALT]: "https://uy.dewalt.global",
			[Brands.STANLEY]: "https://uy.stanleytools.global",
			[Brands.BLACK_AND_DECKER]: "https://uy.blackanddecker.global",
		}
	),
	VENEZUELA: new Country(
		"Venezuela",
		{
			[Brands.DEWALT]: "https://ve.dewalt.global/",
			[Brands.STANLEY]: "https://ve.stanleytools.global",
			[Brands.BLACK_AND_DECKER]: "https://ve.blackanddecker.global",
		}
	),
	VIETNAM: new Country("Vietnam"),
	OTHERS: new Country("Others"),

	// European Countries
	GERMANY: Country.createWithAliases(
		"Germany",
		["Germany", "Black and Decker - Germany", "Stanley - Germany", "Bostitch - Germany", "Dewalt - Germany", "Expert - Germany", "Facom - Germany", "Irwin - Germany", "Lenox - Germany"],
		{
			[Brands.DEWALT]: "https://www.dewalt.de/",
			[Brands.STANLEY]: "https://www.stanleyworks.de/",
			[Brands.BLACK_AND_DECKER]: "https://www.blackanddecker.de/",
			[Brands.BOSTITCH]: "https://stanley-bostitch.de",
			[Brands.EXPERT]: "https://www.facom.de/why-facom.html",
			[Brands.FACOM]: "https://facom.de",
			[Brands.IRWIN]: "https://irwin.de",
			[Brands.LENOX]: "https://lenoxtools.eu",
			[Brands.STANLEY]: "https://stanleyworks.de"
		}
	),
	SPAIN: Country.createWithAliases(
		"Spain",
		["Spain", "Black and Decker - Spain", "Bostitch - Spain", "Craftsman - Spain", "Dewalt - Spain", "Expert - Spain", "Facom - Spain", "Lenox - Spain", "Stanley - Spain"],
		{
			[Brands.BLACK_AND_DECKER]: "https://www.blackanddecker.es/",
			[Brands.BOSTITCH]: "https://bostitch.es/",
			[Brands.DEWALT]: "https://www.dewalt.es/",
			[Brands.EXPERT]: "https://www.facom.es/why-facom.html",
			[Brands.FACOM]: "https://www.facom.es/",
			[Brands.LENOX]: "https://www.lenoxtools.es/" ,
			[Brands.STANLEY]: "https://www.stanleyworks.es/"
		}
	),
	PORTUGAL: Country.createWithAliases(
		"Portugal",
		["Portugal", "Black and Decker - Portugal", "Dewalt - Portugal", "Expert - Portugal", "Facom - Portugal", "Stanley - Portugal"],
		{
			[Brands.BLACK_AND_DECKER]: "https://www.blackanddecker.pt/",
			[Brands.DEWALT]: "https://www.dewalt.pt/",
			[Brands.EXPERT]: "https://www.facom.com/pt/why-facom.html",
			[Brands.FACOM]: "https://www.facom.com/pt/",
			[Brands.STANLEY]: "https://www.stanleyworks.pt/"
		}
	),
	UK: Country.createWithAliases(
		"UK",
		["UK", "Black and Decker - UK", "Bostitch - UK", "Dewalt - UK", "Expert - UK", "Facom - UK", "Irwin - UK", "Lenox - UK", "MacTools - UK", "Stanley - UK"],
		{
			[Brands.BLACK_AND_DECKER]: "https://www.blackanddecker.co.uk/en-gb/",
			[Brands.BOSTITCH]: "https://bostitch.co.uk/",
			[Brands.DEWALT]: "https://www.dewalt.co.uk/",
			[Brands.EXPERT]: "https://www.facom.com/uk/why-facom.html",
			[Brands.FACOM]: "https://www.facom.com/uk/",
			[Brands.IRWIN]: "https://www.irwin.co.uk/",
			[Brands.LENOX]: "https://www.lenoxtools.co.uk/",
			[Brands.MAC_TOOLS]: "http://www.mactools.co.uk/",
			[Brands.STANLEY]: "https://www.stanleytools.co.uk/"
		}
	),
	IRELAND: Country.createWithAliases(
		"Ireland",
		["Ireland", "Black and Decker - Ireland", "Bostitch - Ireland", "Dewalt - Ireland", ],
		{
			[Brands.BLACK_AND_DECKER]: "https://www.blackanddecker.ie/",
			[Brands.BOSTITCH]: "https://bostitch.ie/",
			[Brands.DEWALT]: "https://www.dewalt.ie/"
		}
	),
	AUSTRALIA: Country.createWithAliases(
		"Australia",
		["Australia", "Black and Decker - Australia", "Irwin - Australia", "Dewalt - Australia", "Lenox - Australia", "Stanley - Australia", "Sidchrome - Australia"],
		{
			[Brands.BLACK_AND_DECKER]: "https://service.blackanddecker.com.au/BD/AU/en-ROW/",
			[Brands.DEWALT]: "https://www.dewalt.com.au/",
			[Brands.IRWIN]: "https://www.irwin.com.au/",
			[Brands.LENOX]: "https://www.lenoxtools.com.au/",
			[Brands.STANLEY]: "https://www.stanleytools.com.au/",
			[Brands.SIDCHROME]: "https://www.sidchrome.com.au/"
		}
	),
	NEW_ZEALAND: Country.createWithAliases(
		"New Zealand",
		["New Zealand", "Black and Decker - New Zealand", "Dewalt - New Zealand", "Stanley - New Zealand"],
		{
			[Brands.BLACK_AND_DECKER]: "https://www.blackanddecker.co.nz/",
			[Brands.DEWALT]: "https://www.dewalt.co.nz/",
			[Brands.STANLEY]: "https://www.stanleytools.co.nz/"
		}
	),
	ITALY: Country.createWithAliases(
		"Italy",
		["Italy", "Black and Decker - Italy", "Bostitch - Italy", "Dewalt - Italy", "Facom - Italy", "Stanley - Italy", "USAG - Italy"],
		{
			[Brands.BLACK_AND_DECKER]: "https://www.blackanddecker.it/",
			[Brands.BOSTITCH]: "https://bostitch.it/",
			[Brands.DEWALT]: "https://www.dewalt.it/",
			[Brands.EXPERT]: "https://www.facom.eu/it-it/",
			[Brands.FACOM]: "https://www.facom.com/it/",
			[Brands.STANLEY]: "https://www.stanley.it/",
			[Brands.USAG]: "https://usag.it/"
		}
	),
	AUSTRIA: Country.createWithAliases(
		"Austria",
		["Austria", "Black and Decker - Austria", "Dewalt - Austria", "Expert - Austria", "Facom - Austria"],
		{
			[Brands.BLACK_AND_DECKER]: "https://www.blackanddecker.at/",
			[Brands.DEWALT]: "https://www.dewalt.at/",
			[Brands.EXPERT]: "https://www.facom.com/at/",
			[Brands.FACOM]: "https://www.facom.com/at/"
		}
	),
	FRANCE: Country.createWithAliases(
		"France",
		["France", "Black and Decker - France", "Bostitch - France", "Dewalt - France", "Facom - France", "Expert - France", "Stanley - France"],
		{
			[Brands.BLACK_AND_DECKER]: "https://www.blackanddecker.fr/",
			[Brands.BOSTITCH]: "https://bostitch.fr/",
			[Brands.DEWALT]: "https://www.dewalt.fr/",
			[Brands.EXPERT]: "https://www.facom.fr/why-facom.html",
			[Brands.FACOM]: "https://www.facom.fr/",
			[Brands.STANLEY]: "https://www.stanleyoutillage.fr/"
		}
	),
	POLAND: Country.createWithAliases(
		"Poland",
		["Poland", "Black and Decker - Poland", "Bostitch - Poland", "Dewalt - Poland", "Facom - Poland","Stanley - Poland"],
		{
			[Brands.BLACK_AND_DECKER]: "https://www.blackanddecker.pl/",
			[Brands.BOSTITCH]: "https://bostitch.pl/",
			[Brands.DEWALT]: "https://www.dewalt.pl/",
			[Brands.FACOM]: "https://www.facom.pl/",
			[Brands.STANLEY]: "https://www.stanleyworks.pl/"
		}
	),
	BELGIUM_DUTCH: Country.createWithAliases(
		"Belgium - Dutch",
		["Belgium", "Black and Decker - Belgium - Dutch", "Dewalt - Belgium - Dutch", "Facom - Belgium - Dutch","Stanley - Belgium - Dutch"],
		{
			[Brands.BLACK_AND_DECKER]: "https://www.blackanddecker.be/",
			[Brands.DEWALT]: "https://www.dewalt.be/",
			[Brands.FACOM]: "https://www.facom.be/home.html?request_locale=nl",
			[Brands.STANLEY]: "https://www.stanleyworks.be/"
		}
	),
	BELGIUM_FRENCH: Country.createWithAliases(
		"Belgium - French",
		["Belgium", "Black and Decker - Belgium - French", "Dewalt - Belgium - French", "Facom - Belgium - French","Stanley - Belgium - French"],
		{
			[Brands.BLACK_AND_DECKER]: "https://www.blackanddecker.be/",
			[Brands.DEWALT]: "https://www.dewalt.be/Accueil.html?request_locale=fr",
			[Brands.FACOM]: "https://www.facom.be/",
			[Brands.STANLEY]: "https://fr.stanleyworks.be/?_ga=2.27745852.1822665306.1634050892-1542802996.1634050892"
		}
	),
	NETHERLANDS: Country.createWithAliases(
		"Netherlands",
		["Netherlands", "Black and Decker - Netherlands", "Dewalt - Netherlands", "Facom - Netherlands","Irwin - Netherlands","Stanley - Netherlands"],
		{
			[Brands.BLACK_AND_DECKER]: "https://www.blackanddecker.nl/",
			[Brands.DEWALT]: "https://www.dewalt.nl/",
			[Brands.FACOM]: "https://www.facom.nl/",
			[Brands.IRWIN]: "https://www.irwin.nl/",
			[Brands.STANLEY]: "https://www.stanleyworks.nl/"
		}
	),
	LUXEMBOURG: Country.createWithAliases(
		"Luxembourg",
		["Luxembourg", "Black and Decker - Luxembourg","Bostitch - Luxembourg", "Dewalt - Luxembourg", "Expert - Luxembourg","Facom - Luxembourg","Stanley - Luxembourg"],
		{
			[Brands.BLACK_AND_DECKER]: "https://www.blackanddecker.fr/",
			[Brands.BOSTITCH]: "https://bostitch.fr/",
			[Brands.DEWALT]: "https://www.dewalt.fr/",
			[Brands.EXPERT]: "https://www.facom.fr/why-facom.html",
			[Brands.FACOM]: "https://www.facom.fr/",
			[Brands.STANLEY]: "https://www.stanleyoutillage.fr/"
		}
	),
	NORWAY: Country.createWithAliases(
		"Norway",
		["Norway", "Black and Decker - Norway", "Dewalt - Norway","Stanley - Norway"],
		{
			[Brands.BLACK_AND_DECKER]: "https://www.blackanddecker.no/",
			[Brands.DEWALT]: "https://www.dewalt.no/",
			[Brands.STANLEY]: "https://www.stanleyworks.no/"
		}
	),
	SWEDEN: Country.createWithAliases(
		"Sweden",
		["Sweden", "Black and Decker - Sweden", "Dewalt - Sweden","Stanley - Sweden"],
		{
			[Brands.BLACK_AND_DECKER]: "https://www.blackanddecker.se/",
			[Brands.DEWALT]: "https://www.dewalt.se/",
			[Brands.STANLEY]: "https://www.stanleyworks.se/"
		}
	),
	DENMARK: Country.createWithAliases(
		"Denmark",
		["Denmark", "Black and Decker - Denmark", "Dewalt - Denmark","Stanley - Denmark"],
		{
			[Brands.BLACK_AND_DECKER]: "https://www.blackanddecker.dk/",
			[Brands.DEWALT]: "https://www.dewalt.dk/",
			[Brands.STANLEY]: "https://www.stanleyworks.dk/"
		}
	),
	FINLAND: Country.createWithAliases(
		"Finland",
		["Finland", "Black and Decker - Finland", "Dewalt - Finland","Stanley - Finland"],
		{
			[Brands.BLACK_AND_DECKER]: "https://www.blackanddecker.fi/",
			[Brands.DEWALT]: "https://www.dewalt.fi/",
			[Brands.STANLEY]: "https://www.stanleyworks.fi/"
		}
	),
	CZECH_REPUBLIC: Country.createWithAliases(
		"Czech Republic",
		["Czech Republic", "Black and Decker - Czech Republic","Bostitch - Czech Republic", "Dewalt - Czech Republic"],
		{
			[Brands.BLACK_AND_DECKER]: "https://www.blackanddecker.cz/",
			[Brands.BOSTITCH]: "https://bostitch.cz/",
			[Brands.DEWALT]: "https://www.dewalt.cz/"
		}
	),
	SLOVAKIA: Country.createWithAliases(
		"Slovakia",
		["Slovakia", "Black and Decker - Slovakia","Bostitch - Slovakia", "Dewalt - Slovakia", "Facom - Slovakia", "Irwin - Slovakia"],
		{
			[Brands.BLACK_AND_DECKER]: "https://www.blackanddecker.sk/",
			[Brands.BOSTITCH]: "https://bostitch.sk/",
			[Brands.DEWALT]: "https://www.dewalt.sk/",
			[Brands.FACOM]: "https://facom.sk/",
			[Brands.IRWIN]: "https://irwin.sk/"
		}
	),
	SWITZERLAND_DEUTSCH: Country.createWithAliases(
		"Switzerland - Deutsch",
		["Switzerland", "Black and Decker - Switzerland - Deutsch","Bostitch - Switzerland - Deutsch", "Dewalt - Switzerland - Deutsch", "Facom - Switzerland - Deutsch","Stanley - Switzerland - Deutsch"],
		{
			[Brands.BLACK_AND_DECKER]: "https://www.blackanddecker.ch/",
			[Brands.BOSTITCH]: "https://de.bostitch.ch/",
			[Brands.DEWALT]: "https://www.dewalt.ch/de/",
			[Brands.FACOM]: "https://www.facom.com/ch/Homepage.html?request_locale=de",
			[Brands.STANLEY]: "https://www.stanleyworks.ch/"
		}
	),
	SWITZERLAND_ITALIAN: Country.createWithAliases(
		"Switzerland - Italian",
		["Switzerland", "Black and Decker - Switzerland - Italian","Bostitch - Switzerland - Italian", "Dewalt - Switzerland - Italian", "Facom - Switzerland - Italian","Stanley - Switzerland - Italian"],
		{
			[Brands.BLACK_AND_DECKER]: "https://www.blackanddecker.ch/it-ch?_ga=2.221542108.571396902.1636474793-1021433201.1636474793",
			[Brands.BOSTITCH]: "https://it.bostitch.ch/",
			[Brands.DEWALT]: "https://www.dewalt.ch/it/",
			[Brands.FACOM]: "https://www.facom.com/ch/Pagina-iniziale.html?request_locale=it",
			[Brands.STANLEY]: "https://www.stanleyworks.ch/"
		}
	),
	SWITZERLAND_FRENCH: Country.createWithAliases(
		"Switzerland - French",
		["Switzerland", "Black and Decker - Switzerland - French","Bostitch - Switzerland - French", "Dewalt - Switzerland - French", "Facom - Switzerland - French","Stanley - Switzerland - French"],
		{
			[Brands.BLACK_AND_DECKER]: "https://www.blackanddecker.ch/fr-ch?_ga=2.249148099.571396902.1636474793-1021433201.1636474793",
			[Brands.BOSTITCH]: "https://fr.bostitch.ch/",
			[Brands.DEWALT]: "https://www.dewalt.ch/fr/",
			[Brands.FACOM]: "https://www.facom.com/ch/Accueil.html?request_locale=fr",
			[Brands.STANLEY]: "https://www.stanleyworks.ch/"
		}
	),
	ROMANIA: Country.createWithAliases(
		"Romania",
		["Romania", "Black and Decker - Romania", "Dewalt - Romania","Stanley - Romania"],
		{
			[Brands.BLACK_AND_DECKER]: "https://www.blackanddecker.ro/",
			[Brands.DEWALT]: "https://www.dewalt.ro/",
			[Brands.STANLEY]: "https://www.stanleyworks.ro/"
		}
	),
	HUNGARY: Country.createWithAliases(
		"Hungary",
		["Hungary", "Black and Decker - Hungary", "Bostitch - Hungary","Dewalt - Hungary","Stanley - Hungary"],
		{
			[Brands.BLACK_AND_DECKER]: "https://www.blackanddecker.hu/",
			[Brands.BOSTITCH]: "http://atro-bostitch.hu/",
			[Brands.DEWALT]: "https://www.dewalt.hu/",
			[Brands.STANLEY]: "https://www.stanleyworks.hu/"
		}
	)
	// USA: Country.createWithAliases(
	// 	"USA",
	// 	{
	// 		[Brands.BLACK_AND_DECKER]: "https://www.blackanddecker.com/",
	// 		[Brands.BOSTITCH]: "https://www.bostitch.com/",
	// 		[Brands.CRAFTSMAN]: "https://cloud.emails.craftsman.com/craftsman_customer_service_sign_up",
	// 		[Brands.DEWALT]: "https://www.dewalt.com/",
	// 		[Brands.IRWIN]: "https://www.irwin.com/",
	// 		[Brands.LENOX]: "https://www.lenoxtools.com/",
	// 		[Brands.PORTER_CABLE]: "https://www.portercable.com/",
	// 		[Brands.STANLEY]:"https://www.stanleytools.com/"
	// 	}
	// ),
});
// INSIDE OTHERS:
// Management Team, Data, Demo, English Speaking Arab Group, other markets,
// Other Markets out of support, Testing, Test_Views, Total Care, TPCO, TPCO_P,
// TPCO_S, TPROA, TPTUR
