import React from "react";
import Layout from "./Layout";
import i18n from "./i18n";
import swal from "sweetalert";
import Loader from "react-loader-spinner";

import { withTranslation } from "react-i18next";
import { getSurveyInfo } from "./utils/ApiManager";
import { themes, Languages, Brands } from "./utils/Config";
import "./components/styles/Loader.css";

class LayoutContainter extends React.Component {
	constructor(props) {
		super(props);
		this.setLanguage = this.setLanguage.bind(this);
		this.setThemeByBrand = this.setThemeByBrand.bind(this);
		this.state = {
			customerName: undefined,
			loadingApp: true,
			isValidUrl: true,
			surveyHasAnswer: false,
			langContext: {
				language: Languages.ENGLISH,
				setLanguage: this.setLanguage,
			},
			brandContext: {
				name: Brands.STANLEY,
				theme: themes[Brands.STANLEY.toLowerCase()],
				setThemeByBrand: this.setThemeByBrand,
			},
		};
	}

	changeFavIcon() {
		let brand = this.state.brandContext.name;
		document.title = `Satisfaction Survey | ${brand}`;
		let link = document.querySelector('link[rel="shortcut icon"]') || document.querySelector('link[rel="icon"]');

		if (!link) {
			link = document.createElement("link");
			link.id = "favicon";
			link.rel = "shortcut icon";
			document.head.appendChild(link);
		}

		if (!brand) {
			brand = "stanley";
		} else {
			brand = brand.toLowerCase();
			const searchRegExp = / /g;
			const replaceWith = "_";
			brand = brand.replace(searchRegExp, replaceWith);
		}
		link.href = `/icons/${brand}.ico`;
	}

	async componentDidMount() {
		await this.validateParams();
	}

	componentDidUpdate() {
		this.changeFavIcon();
		this.validateSurveyStatus();
	}

	showAlertForInvalidUrl(t, error404, buttons) {
		const title = error404 ? t("pop-up.invalid-link.title") + " Error 404" : t("pop-up.invalid-link.title");
		swal({
			title: title,
			text: t("pop-up.invalid-link.description"),
			icon: "error",
			closeOnClickOutside: buttons,
			buttons: buttons,
		});
	}

	onInvalidUrl(t, error404, buttons) {
		this.setState({ isValidUrl: false });
		if (error404) {
			this.setupDefaultSurvey();
		}
		this.showAlertForInvalidUrl(t, error404, buttons);
	}

	showAlertForAnswerDetected(t) {
		swal(t("pop-up.survey-has-answer.title"), t("pop-up.survey-has-answer.description"), "error");
	}

	storeCustomerName(ticketJson) {
		let userName = ticketJson.ticket.custom_fields.find((field) => {
			return (field.id === 360024203274 || field.id === 360016006354) && field.value !== null;
		});

		if (userName !== undefined && userName !== null) {
			userName = userName.value.split(" ")[0];
			this.setState({ ...this.state, customerName: userName });
		}
	}

	hasAnswer(surveyJson) {
		return surveyJson.attributes.state;
	}

	async validateParams() {
		const { t } = this.props;
		const queryStr = window.location.search;
		const urlParams = new URLSearchParams(queryStr);
		const surveyId = urlParams.get("survey_id");

		if (!surveyId) {
			this.onInvalidUrl(t, true, false);
			return;
		}

		// Extract ticket info
		const surveyJson = (await getSurveyInfo(surveyId)).result;
		if (!surveyJson) {
			this.onInvalidUrl(t, true, false);
			return;
		}

		const groupName = surveyJson.attributes.ticket_group;
		const lang = surveyJson.attributes.requester_language;
		const brand = surveyJson.attributes.ticket_brand;
		const surveyTags = surveyJson.attributes.ticket_tags;

		let customerName = surveyJson.attributes.requester_name;
		if (customerName) {
			customerName = customerName.split(" ")[0];
		}

		await this.setupNewSurvey(surveyId, customerName, groupName, lang, brand, surveyTags, this.hasAnswer(surveyJson), true);
	}

	async setupNewSurvey(surveyId, customerName, groupName, lang, brandName, surveyTags, hasAnswer, isValidUrl) {
		this.setThemeByBrand(brandName);
		this.setState({
			...this.state,
			isValidUrl: isValidUrl,
			surveyId: surveyId,
			customerName: customerName,
			groupName: groupName,
			brand: brandName,
			surveyTags: surveyTags,
			loadingApp: false,
			surveyHasAnswer: hasAnswer,
		});

		if (lang === null || lang === undefined) {
			lang = Languages.ENGLISH;
		}

		if (lang.includes("-")) {
			lang = lang.split("-")[0];
		}

		this.setLanguage(lang);
	}

	validateSurveyStatus() {
		const { t } = this.props;
		if (!this.state.isValidUrl) {
			this.showAlertForInvalidUrl(t);
			return;
		}
		if (this.state.surveyHasAnswer) {
			this.showAlertForAnswerDetected(t);
			return;
		}
	}

	async setupDefaultSurvey() {
		const brandName = Brands.STANLEY;
		this.setThemeByBrand(brandName);
		this.setState({
			...this.state,
			isValidUrl: true,
			surveyId: null,
			loadingApp: false,
		});
		this.setLanguage(Languages.ENGLISH);
	}

	setLanguage(language) {
		language = language.toLowerCase();
		language = Object.values(Languages).includes(language) ? language : Languages.ENGLISH;
		i18n.changeLanguage(language);
		this.setState({
			langContext: { ...this.state.langContext, language: language },
		});
	}

	setThemeByBrand(brand) {
		const normalizedBrand = brand.replace('®', '').toLowerCase().replace(/ /g, '_').replace('+', 'and');
		let brandTheme = themes[normalizedBrand];
		if (!brandTheme) {
			brandTheme = themes[Brands.STANLEY.toLowerCase()];
		}
		this.setState({
			brandContext: {
				name: normalizedBrand,
				prettyName: brand,
				theme: brandTheme,
			},
		});
	}

	render() {
		return (
			<React.Fragment>
				{(this.state.loadingApp && (
					<div>
						<Loader className="Loader" type="TailSpin" height={80} width={80} color="#FFC528" />
					</div>
				)) || (
					<Layout
						context={this.state}
						surveyHasAnswer={this.state.surveyHasAnswer}
						isValidUrl={this.state.isValidUrl}
						children={this.props.children}
					/>
				)}
			</React.Fragment>
		);
	}
}

export default withTranslation()(LayoutContainter);
